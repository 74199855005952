import gql from 'graphql-tag';

const getEnpsDetails = gql`
  query enpsDetails($tenantId: ID!, $startDate: String!, $endDate: String!, $segmentIds: [ID], $teamIds: [ID], $workspaceIds: [ID]) {
    enpsDetails(tenantId: $tenantId, startDate: $startDate, endDate: $endDate, segmentIds: $segmentIds, teamIds: $teamIds, workspaceIds: $workspaceIds) {
      enpsScore
      enpsExplanation
      enpsDetails {
        detractors
        promoters
        passives
        skipped
      }
      comments {
        text
        createdAt
      }
    }
  }
`;

export default getEnpsDetails;
