import gql from 'graphql-tag';

const getCurrentUserNotifications = gql`
  query getCurrentUserNotifications($tenantId: ID!) {
    notifications {
      id
      isViewed
      type
    }
    pendingInvitations {
      id
      isCompleted
      isIgnored
    }
    unreadFeedback(tenantId: $tenantId) {
      id
    }
  }
`;

export default getCurrentUserNotifications;
