import gql from 'graphql-tag';

const getMemberById = gql`
  query getMemberById($id: ID!, $workspaceId: ID!) {
    workspaceMember(workspaceId: $workspaceId, id: $id) {
      id
      email
      permissions {
        isAdmin
        isOwner
        channelAdminWorkspaces
      }
      person {
        id
        userId
        email
        fullName
        photoUrl
      }
      activeWorkspaceMembership
      celebrationChannelId
      directManager {
        id
        fullName
        email
      }
      isSelf
      hiredOn
      birthday
      totalCoins
      birthdayVisibility
      anniversaryVisibility
      ethnicity
      gender
      jobTitle
      hrisProps {
        department
      }
      customProperties {
        propertyId
        value
      }
    }
  }
`;

export default getMemberById;