import gql from 'graphql-tag';

const getWorkspaceFeed = gql`
  query getWorkspaceFeed($workspaceId: ID!, $offset: Int) {
    workspaceFeed(workspaceId: $workspaceId, offset: $offset) {
      count
      feedItems {
        id
        canDelete
        isMultipleReceiver
        isWholeChannel
        teamNames
        isAdmin
        eventTime
        comments {
          id
          author {
            id
            fullName
            firstName
            photoUrl
            isSelf
          }
          createdAt
          message
          canDelete
        }
        numberComments
        thanks {
          canThank
          canSeeThank
          thanks {
            id
            author {
              id
              fullName
              firstName
              photoUrl
              isSelf
            }
            createdAt
            canDelete
          }
          numberThanks
        }
        payload {
          __typename
          ... on WorkspaceCreation {
            workspaceCreator {
              id
              fullName
              firstName
              email
              photoUrl
            }
          }
          ... on WorkspaceMemberAdded {
            membersAdded {
              joinedAt
              member {
                id
                fullName
                firstName
                email
                photoUrl
              }
            }
          }
          ... on KudosPayload {
						kudos {
              type
              note
            }
            receivers {
              id
              fullName
              firstName
              email
              photoUrl
            }
            giver {
              id
              fullName
              firstName
              email
              photoUrl
            }
      	  }
          ... on RitualStart {
            ritualName
            mainMessage
            secondaryMessage
          }
          ... on RitualProgress {
            ritualName
            mainMessage
            secondaryMessage
            percentProgress
          }
          ... on RitualRecap {
            ritualName
            mainMessage
            secondaryMessage
            feedbackGivers {
              id
              fullName
              firstName
              email
              photoUrl
            }
            biggestGivers {
              id
              fullName
              firstName
              email
              photoUrl
            }
          }
        }
      },
    }
  }
`;

export default getWorkspaceFeed;
