import gql from 'graphql-tag';

const setFeedbackRitualSettings = gql`
  mutation setFeedbackRitualWorkspaceSettings($workspaceId: ID!, $settings: FeedbackRitualWorkspaceSettingsInput!) {
    setFeedbackRitualWorkspaceSettings(workspaceId: $workspaceId, settings: $settings) {
      id
      feedbackRitualSettings {
        isActive
        isReminderActive
        isRecapActive
        mpvCoinsAwarded
        dayOfPost
        kickoffMessage
        frequency
        isLastCallReminderActive
        notifyEntireTeamForLastCall
        notifyEntireTeamForKickoff
        isStatsMessageActive
        kickOffTime
        reminderTime
        lastCallTime
        recapTime
        statsTime
        excludePeopleFromMVP
        customRitualName
        useGifs
        isMvpEnabled
      }
    }
  }
`;

export default setFeedbackRitualSettings;