import gql from 'graphql-tag';

const updateTenant = gql`
  mutation updateTenant($tenantId: ID!, $name: String!, $icon: String, $channelCreationPermission: String) {
    updateTenant(tenantId: $tenantId, name: $name, icon: $icon, channelCreationPermission: $channelCreationPermission) {
      id
      name
      photoUrl
      channelCreationPermission
    }
  }
`;

export default updateTenant;
