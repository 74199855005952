const connectionAllFieldsFragmentName = 'connectionAllFieldsFragment';
export const CONNECTION_ALL_FIELDS_FRAGMENT = {
  name: connectionAllFieldsFragmentName,
  fragment: `fragment ${connectionAllFieldsFragmentName} on Connection {
    id
    isHidden
    peerActionsStatus {
      requestFeedback {
       canBeRequested
       pendingInvitation {
         isRecent
       }
     }
     quickNote {
       canBeGiven
     }
     unsolicitedFeedback {
       canBeGiven
     }
     kudos {
      canBeGiven
    }
   }
    person {
      email
      userId
      userIdHash
      headline
      fullName
      firstName
      photoUrl
    }
    activity {
      type
      time
    }
    activitySummary {
      type
      time
    }
  }`,
};

const skillCommonFieldsFragmentName = 'skillCommonFieldsFragment';
export const SKILL_COMMON_FIELDS_FRAGMENT = {
  name: skillCommonFieldsFragmentName,
  fragment: `fragment ${skillCommonFieldsFragmentName} on Skill {
    id
    name
    slug
    description
    colorPalette
    isCustom
    hasGuide
    abilities {
      name
      id
    }
  }`,
};

const recurringFeedbackFragmentName = 'recurringFeedbackFragment';
export const RECURRING_FEEDBACK_FRAGMENT = {
  name: recurringFeedbackFragmentName,
  fragment: `fragment ${recurringFeedbackFragmentName} on RecurringFeedback {
    id
    customNote
    recurrence {
      day
      frequency
    }
    feedbackTemplate {
      id
      skills {
        id
        name
      }
    }
    nextDeliveryOn
  }`
};

const feedbackReceivedCommonFieldsFragmentName =
  'feedbackReceivedCommonFieldsFragment';
export const FEEDBACK_RECEIVED_COMMON_FIELDS_FRAGMENT = {
  name: feedbackReceivedCommonFieldsFragmentName,
  fragment: `
  ${SKILL_COMMON_FIELDS_FRAGMENT.fragment}

  fragment ${feedbackReceivedCommonFieldsFragmentName} on FeedbackReceived {
    id
    feedItemId
    feedbackType
    isThanked
    completedAt
    isInvitationTypeEmail
    receiver {
      id
      isSelf
      userIdHash
      email
      firstName
      fullName
      photoUrl
      headline
    }
    giver {
      id
      personId
      isSelf
      userIdHash
      email
      firstName
      fullName
      photoUrl
      headline
    }
    numComments
    quickNote {
      note
      skill {
        ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
      }
    }
    skillRatings {
      skill {
        ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
      }
      rating
      note
      actionItems {
        id
        name
        description
      }
    }
    abilityRatings {
      ability {
        id
        name
        description
      }
      rating
    }
    # TODO: deprecate direct access to actionItems. All access should be done via skillRatings.
    actionItems {
      skillRating {
        skill {
          id
          name
          description
          abilities {
            id
          }
        }
        rating
        note
      }
      pickedAbilities {
        id
        name
        description
      }
    }
    latestPerformance {
      rating
      note
    }
    qualityEndorsements {
      name
    }
    kudos {
      note
      type
    }
  }`,
};

const feedItemCommonFieldsFragment = 'feedItemCommonFieldsFragment';
export const FEEDITEM_COMMON_FIELDS_FRAGMENT = {
  name: feedItemCommonFieldsFragment,
  fragment: `
  ${SKILL_COMMON_FIELDS_FRAGMENT.fragment}

  fragment ${feedItemCommonFieldsFragment} on FeedItem {
    id
    canDelete
    isMultipleReceiver
    isAdmin
    createdAt
    eventTime
    comments {
      id
      createdAt
      message
      canDelete
      author {
        id
        fullName
        firstName
        photoUrl
      }
    }
    numberComments
    thanks {
      canThank
      canSeeThank
      thanks {
        id
        author {
          id
          fullName
          firstName
          photoUrl
        }
        createdAt
        canDelete
      }
      numberThanks
    }
    workspace {
      id
      name
      iconUrl
    }
    payload {
      __typename
      ... on KudosPayload {
          feedbackId
          kudos {
            type
            note
          }
          receivers {
            id 
            personId
            fullName
            firstName
            email
            photoUrl
          }
          giver {
            id
            personId
            fullName
            firstName
            email
            photoUrl
          }
      }
      ... on QuickNotePayload {
        feedbackId
        quickNote {
          note
          skill {
            ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
          }
        }
        receiver {
            id 
            personId
            fullName
            firstName
            email
            photoUrl
          }
        giver {
          id
          personId
          fullName
          firstName
          email
          photoUrl
        }
      }
      ... on LongformPayload {
        feedbackId
        feedback {
          id
          feedbackType
          overallRating
          completedAt
          isInvitationTypeEmail
          skillRatings {
            skill {
              ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
            }
            rating
            note
            actionItems {
              id
              name
              description
            }
          }
          abilityRatings {
            ability {
              id
              name
              description
            }
            rating
          }
          qualityEndorsements {
            name
          }
          latestPerformance {
            rating
            note
          }
        }
        receiver {
          id 
          personId
          fullName
          firstName
          email
          photoUrl
        }
        giver {
          id
          personId
          fullName
          firstName
          email
          photoUrl
          recurringFeedback {
            id
            recurrence {
              frequency
            }
          }
        }
      }
    }
  }`
};

const feedbackFlowFragment = 'feedbackFlowCommonFieldsFragment';
export const FEEDBACK_FLOW_FEEDITEM_COMMON_FIELDS_FRAGMENT = {
  name: feedbackFlowFragment,
  fragment: `
  ${SKILL_COMMON_FIELDS_FRAGMENT.fragment}

  fragment ${feedbackFlowFragment} on FeedItem {
    id
    createdAt
    eventTime
    comments {
      id
      createdAt
      message
      canDelete
      author {
        id
        fullName
        firstName
        photoUrl
      }
    }
    numberComments
    thanks {
      canThank
      canSeeThank
      thanks {
        id
        author {
          id
          fullName
          firstName
          photoUrl
        }
        createdAt
        canDelete
      }
      numberThanks
    }
    payload {
      __typename
      ... on KudosPayload {
          feedbackId
          kudos {
            type
            note
          }
          receivers {
            id 
            personId
            fullName
            firstName
            email
            photoUrl
          }
          giver {
            id
            personId
            fullName
            firstName
            email
            photoUrl
          }
      }
      ... on QuickNotePayload {
        feedbackId
        quickNote {
          note
          skill {
            ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
          }
        }
        receiver {
            id 
            personId
            fullName
            firstName
            email
            photoUrl
          }
        giver {
          id
          personId
          fullName
          firstName
          email
          photoUrl
        }
      }
      ... on LongformPayload {
        feedbackId
        feedback {
          id
          feedbackType
          overallRating
          completedAt
          isInvitationTypeEmail
          skillRatings {
            skill {
              ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
            }
            rating
            note
            actionItems {
              id
              name
              description
            }
          }
          abilityRatings {
            ability {
              id
              name
              description
            }
            rating
          }
          qualityEndorsements {
            name
          }
          latestPerformance {
            rating
            note
          }
        }
        receiver {
          id 
          personId
          fullName
          firstName
          email
          photoUrl
        }
        giver {
          id
          personId
          fullName
          firstName
          email
          photoUrl
          recurringFeedback {
            id
            recurrence {
              frequency
              day
            }
          }
        }
      }
    }
  }`
};

const completedFeedbackFeedItemCommonFields = 'completedFeedbackItemsCommonFields';
export const COMPLETED_FEEDBACK_FEEDITEM_COMMON_FIELDS = {
  name: completedFeedbackFeedItemCommonFields,
  fragment: `
  ${SKILL_COMMON_FIELDS_FRAGMENT.fragment}
  fragment ${completedFeedbackFeedItemCommonFields} on FeedItem {
    id
    eventTime
    canDelete
    createdAt
    isMultipleReceiver
    isWholeChannel
    comments {
      id
      createdAt
      message
      canDelete
      author {
        id
        fullName
        firstName
        photoUrl
      }
    }
    thanks {
      canThank
      canSeeThank
      thanks {
        id
        author {
          id
          fullName
          firstName
          photoUrl
        }
        createdAt
        canDelete
      }
      numberThanks
    }
    workspace {
      id
      name
      iconUrl
    }
    payload {
      __typename
      ... on LongformPayload {
        feedbackId
        feedback {
          id
          feedbackType
          completedAt
          overallRating
          skillRatings {
            skill {
              ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
            }
            rating
            note
            actionItems {
              id
              name
              description
            }
          }
          abilityRatings {
            ability {
              id
              name
              description
            }
            rating
          }
          qualityEndorsements {
            name
          }
          latestPerformance {
            rating
            note
          }
        }
        receiver {
          id 
          personId
          fullName
          firstName
          email
          photoUrl
        }
        giver {
          id
          personId
          fullName
          firstName
          email
          photoUrl
        }
      }
      ... on QuickNotePayload {
        feedbackId
        quickNote {
          note
          skill {
            ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
          }
        }
        receiver {
          id 
          personId
          fullName
          firstName
          email
          photoUrl
        }
        giver {
          id
          personId
          fullName
          firstName
          email
          photoUrl
        }
      }
      ... on KudosPayload {
        feedbackId
        kudos {
          type
          note
        }
        receivers {
          id 
          personId
          fullName
          firstName
          email
          photoUrl
        }
        giver {
          id
          personId
          fullName
          firstName
          email
          photoUrl
        }
      }
    }
  }`
};

const loginFragmentName = 'loginFragment';
export const LOGIN_FRAGMENT = {
  name: loginFragmentName,
  fragment: `fragment ${loginFragmentName} on SigninPayload {
    isNewRecord
    redirectToWorkspaceId
    redirect
    user {
      id
      email
      phoneNumber
      isOnboarded
    }
  }`,
};

const skillAllFieldsFragmentName = 'skillAllFieldsFragment';
export const SKILL_ALL_FIELDS_FRAGMENT = {
  name: skillAllFieldsFragmentName,
  fragment: `fragment ${skillAllFieldsFragmentName} on Skill {
    id
    name
    isCustom
    description
    colorPalette
    slug
    hasGuide
    isCustom
  }`,
};

const userNotificationSettingsFragmentName = 'userNotificationSettingsFragment';
export const USER_NOTIFICATION_SETTINGS_FRAGMENT = {
  name: userNotificationSettingsFragmentName,
  fragment: `fragment ${userNotificationSettingsFragmentName} on NotificationSettings {
    sms {
      label
      value
      isSet
    }
    email {
      label
      value
      isSet
    }
  }`,
};

const wowStatementAllFieldsFragmentName = 'wowStatementAllFieldsFragment';
export const WOW_STATEMENT_ALL_FIELDS_FRAGMENT = {
  name: wowStatementAllFieldsFragmentName,
  fragment: `
  ${SKILL_COMMON_FIELDS_FRAGMENT.fragment}

  fragment ${wowStatementAllFieldsFragmentName} on WowStatement {
    type
    qualities {
      id
      name
    }
  }`,
};

const feedbackRequestRemindersFieldsFragmentName =
  'feedbackRequestRemindersFragment';
export const FEEDBACK_REQUEST_REMINDERS_FIELDS_FRAGMENT = {
  name: feedbackRequestRemindersFieldsFragmentName,
  fragment: `fragment ${feedbackRequestRemindersFieldsFragmentName} on FeedbackRequestReminderDetails {
    next
  }`,
};

const feedbackRequestStatusTypesFragmentName = 'feedbackRequestStatusFragment';
export const FEEDBACK_REQUEST_STATUS_TYPES_FRAGMENT = {
  name: feedbackRequestStatusTypesFragmentName,
  fragment: `fragment ${feedbackRequestStatusTypesFragmentName} on FeedbackRequestStatus {
    type
    subType
  }`,
};

const accountSettingsFragmentName = 'accountSettingsFragment';
export const ACCOUNT_SETTINGS_FRAGMENT = {
  name: accountSettingsFragmentName,
  fragment: `fragment ${accountSettingsFragmentName} on User {
    id
    emails {
      id
      value
      primary
      verified
      updatedAt
    }
    fullName
  }`,
};

/*****************************************************************************/
/* SLACK WORKSPACE FRAGMENTS                                                 */
/*****************************************************************************/
const slackUserFragmentName = 'slackUserFragment';
export const SLACK_USER_FRAGMENT = {
  name: slackUserFragmentName,
  fragment: `fragment ${slackUserFragmentName} on SlackUser {
    isBot
    displayName
    fullName
    email
    isGuest
    matterUser {
      id
      fullName
      email
      isSelf
      photoUrl
    }
    profile {
      image_48
      image_72
      image_192
    }
  }`,
};

const slackWorkspaceFragmentName = 'slackWorkspaceFragment';
export const SLACK_WORKSPACE_FRAGMENT = {
  name: slackWorkspaceFragmentName,
  fragment: `
  ${SLACK_USER_FRAGMENT.fragment}
  fragment ${slackWorkspaceFragmentName} on SlackWorkspace {
    id
    teamName
    postAsUserOAuthURL
    primaryChannel
    members {
      ...${SLACK_USER_FRAGMENT.name}
    }
    slackTeamId
  }`,
};

/*****************************************************************************/
/* FEEDBACK RITUAL WORKSPACE SETTINGS FRAGMENTS                              */
/*****************************************************************************/
const feedbackRitualWorkspaceSettingsFragmentName =
  'feedbackRitualWorkspaceSettingsFragment';
export const FEEDBACK_RITUAL_WORKSPACE_SETTINGS_FRAGMENT = {
  name: feedbackRitualWorkspaceSettingsFragmentName,
  fragment: `
  ${SLACK_USER_FRAGMENT.fragment}
  fragment ${feedbackRitualWorkspaceSettingsFragmentName} on FeedbackRitualWorkspaceSettings {
    isActive
    isReminderActive
    isRecapActive
    dayOfPost
    kickoffMessage
    frequency
    isLastCallReminderActive
    notifyEntireTeamForLastCall
    notifyEntireTeamForKickoff
    isStatsMessageActive
    kickOffTime
    reminderTime
    lastCallTime
    recapTime
    excludePeopleFromMVP
    mpvCoinsAwarded
  }`,
};


const workspaceMembersBasicFragmentName = 'workspaceMembersBasicFragment';
export const WORKSPACE_MEMBERS_BASIC_FRAGMENT = {
  name: workspaceMembersBasicFragmentName,
  fragment: `
  fragment ${workspaceMembersBasicFragmentName} on Workspace {
    id
    name
    iconUrl
    members {
      id
      isOwner
      isAdmin
      isSelf
      status
      person {
        id
        userId
      }
    }
    stripeCustomerId
    stripeDowngradeReason
    stripeDowngradeSubscriptionId
  }`,
};
