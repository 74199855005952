import gql from 'graphql-tag';
import {
  CONNECTION_ALL_FIELDS_FRAGMENT,
  SKILL_COMMON_FIELDS_FRAGMENT,
  LOGIN_FRAGMENT,
  SKILL_ALL_FIELDS_FRAGMENT,
  USER_NOTIFICATION_SETTINGS_FRAGMENT,
  FEEDBACK_REQUEST_REMINDERS_FIELDS_FRAGMENT,
  FEEDBACK_REQUEST_STATUS_TYPES_FRAGMENT,
  ACCOUNT_SETTINGS_FRAGMENT,
  FEEDITEM_COMMON_FIELDS_FRAGMENT,
  COMPLETED_FEEDBACK_FEEDITEM_COMMON_FIELDS,
  FEEDBACK_FLOW_FEEDITEM_COMMON_FIELDS_FRAGMENT,
  RECURRING_FEEDBACK_FRAGMENT
} from '../fragments';

export const CURRENT_USER_QUERY = gql`
  query {
    currentUser {
      id
      userIdHash
      email
      fullName
      photoUrl
      headline
    }
  }
`;

export const EMAIL_LOGIN_CODE_IF_USER_EXISTS_MUTATION = gql`
  mutation($email: String!) {
    emailLoginCodeIfUserExists(email: $email)
  }
`;

export const CONNECTED_ACCOUNTS_QUERY = gql`
  query connectedAccounts {
    currentUser {
      id
    }
  }
`;

export const CURRENT_USER_STATUS_QUERY = gql`
  query currentUserStatus {
    currentUser {
      id
      photoUrl
      isOnboarded
      firstName
      fullName
      email
      emails {
        id
        value
        primary
        verified
        updatedAt
      }
      pickedSkillDetails {
        skill {
          id
          name
        }
      }
      phoneNumber
      userHash
    }
  }
`;

export const FEEDBACK_RECEIVED_QUERY = gql`
  ${SKILL_COMMON_FIELDS_FRAGMENT.fragment}
  ${COMPLETED_FEEDBACK_FEEDITEM_COMMON_FIELDS.fragment}
  query($id: ID!) {
    feedbackFeedItemByFeedbackId(feedbackId: $id) {
      ...${COMPLETED_FEEDBACK_FEEDITEM_COMMON_FIELDS.name}
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation logout {
    logout
  }
`;

export const PEER_RECOMMENDATIONS_QUERY = gql`
query {
  currentUser {
    id
  }
}
`;

export const PENDING_INVITATIONS_QUERY = gql`
  query {
    pendingInvitations {
      id
      isCompleted
      isIgnored
      eventTime
      path
      owner {
        id
        email
        headline
        fullName
        photoUrl
      }
    }
  }
`;

export const PENDING_INVITATIONS_QUERY_MIN = gql`
  query {
    pendingInvitations {
      id
    }
  }
`;

export const OUTBOUND_INVITATIONS_QUERY = gql`
  ${FEEDBACK_REQUEST_REMINDERS_FIELDS_FRAGMENT.fragment}
  ${FEEDBACK_REQUEST_STATUS_TYPES_FRAGMENT.fragment}
  ${COMPLETED_FEEDBACK_FEEDITEM_COMMON_FIELDS.fragment}

  query outboundInvitations {
    outboundInvitations {
      id
      uuid
      eventTime
      customNote
      recurringFeedback {
        id
        recurrence {
          frequency
          day
        }
      }
      lastCompletedFeedbackFeedItem {
        ...${COMPLETED_FEEDBACK_FEEDITEM_COMMON_FIELDS.name}
      }
      reminders {
        ...${FEEDBACK_REQUEST_REMINDERS_FIELDS_FRAGMENT.name}
      }
      invitedPeer {
        email
        fullName
        firstName
        userId
        photoUrl
      }
      feedbackRequestStatus {
        ...${FEEDBACK_REQUEST_STATUS_TYPES_FRAGMENT.name}
        changedAt
      }
      skillsStatus {
        type
        skills {
          id
          name
        }
      }
      replacement {
        id
      }
    }
    currentUser {
      id
      fullName
      firstName
    }
  }
`;

export const REMOTE_NOTIFICATIONS_QUERY = gql`
  query {
    notifications {
      id
      type
      isViewed
      eventTime
      resourcePath
      workspaceInvitationToken
      payload {
        subjectPerson {
          email
          fullName
          photoUrl
        }
        skill {
          id
          name
        }
        invitation {
          id
          invitedPeer {
            fullName
            email
            photoUrl
          }
          events {
            name
            time
          }
        }
        workspace {
          name
          id
          iconUrl
        }
      }
    }
  }
`;

export const SKILLS_IN_FEEDBACK_QUERY = gql`
${SKILL_COMMON_FIELDS_FRAGMENT.fragment}

query ($invitationUuid: ID!) {
  giveFeedbackSession(
    finder: { id: $invitationUuid, finderType: INVITATION_UUID }
  ) {
    id
    feedbackType
    receiver {
      firstName
      lastName
      fullName
      photoUrl
    }
    skillsToRateWithMetaData {
      skill {
        ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
      }
      priorRating
      currentRating
      currentNote
    }
  }
}
`;

export const FEEDBACK_TIMELINE_QUERY = gql`
${SKILL_COMMON_FIELDS_FRAGMENT.fragment}

query giveFeedbackSession($invitationUuid: ID!) {
  giveFeedbackSession(
    finder: { id: $invitationUuid, finderType: INVITATION_UUID }
  ) {
    didRateAllSkills
    id
    feedbackType
    overallRating
    selectedSkillIds
    receiver {
      qualitiesToRate {
        id
        name
      }
      firstName
      lastName
      fullName
      photoUrl
      headline
    }
    skillsToRateWithMetaData {
      skill {
        ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
      }
      priorRating
      currentRating
      currentNote
      actionItemSelections {
        ability {
          name
          id
          description
        }
        selected
      }
    }
  }
  invitation(invitationUuid: $invitationUuid) {
    owner {
      id
      firstName
      fullName
      photoUrl
      headline
      isDeleted
    }
    giverFullName
    recurringFeedback {
        id
        recurrence {
          frequency
        }
      }
  }
}
`;

export const CONTACTS_LIST_MUTATION = gql`
  mutation($nameOrEmail: String!) {
    findContactsByNameOrEmail(nameOrEmail: $nameOrEmail) {
      fullName
      email
      userId
    }
  }
`;

export const REMOVE_NOTE_FROM_SKILL_RATING_MUTATION = gql`
  mutation($invitationUuid: ID!, $skillId: ID!) {
    removeNoteFromSkillRating(
      finder: { id: $invitationUuid, finderType: INVITATION_UUID }
      skillId: $skillId
    )
  }
`;

export const EMAIL_SIGNUP_LOGIN_MUTATION = gql`
${LOGIN_FRAGMENT.fragment}

mutation (
  $email: String!
  $fullName: String
  $workspaceToken: String
) {
  login (authProvider: {
    emailSignup: {
      email: $email
      fullName: $fullName
      workspaceToken: $workspaceToken
    }
  }) {
      ...${LOGIN_FRAGMENT.name}
    }
}
`;

export const FEEDBACK_INVITATION_TOKEN_LOGIN_MUTATION = gql`
${LOGIN_FRAGMENT.fragment}

mutation loginWithFeedbackInvitationToken(
  $token: String!
) {
  login(authProvider: {
    feedbackInvitationToken: {
      token: $token
    }
  }) {
    ...${LOGIN_FRAGMENT.name}
  }
}
`;

export const GOOGLE_YOLO_LOGIN_MUTATION = gql`
${LOGIN_FRAGMENT.fragment}

mutation loginWithGoogleYolo(
  $token: String!
) {
  login(authProvider: {
    googleYolo: {
      token: $token
    }
  }) {
    ...${LOGIN_FRAGMENT.name}
  }
}
`;

export const SLACK_LOGIN_MUTATION = gql`
${LOGIN_FRAGMENT.fragment}

mutation loginWithSlackToken(
  $token: String!
) {
  login(authProvider: {
    slackSignin: {
      token: $token
    }
  }) {
    ...${LOGIN_FRAGMENT.name}
  }
}
`;

export const MAGIC_LINK_TOKEN_LOGIN_MUTATION = gql`
${LOGIN_FRAGMENT.fragment}

mutation loginWithMagicLinkToken(
  $token: String!
) {
  login(authProvider: {
    magicLinkToken: {
      token: $token
    }
  }) {
    ...${LOGIN_FRAGMENT.name}
  }
}
`;

export const VERIFY_EMAIL_TOKEN_LOGIN_MUTATION = gql`
${LOGIN_FRAGMENT.fragment}

mutation verifyEmailToken(
  $token: String!) {
  login(authProvider: {
    verifyEmailToken: {
      token: $token
    }
  }) {
    ...${LOGIN_FRAGMENT.name}
  }
}
`;

export const EMAIL_LOGIN_TOKEN_LOGIN_MUTATION = gql`
${LOGIN_FRAGMENT.fragment}

mutation loginWithEmailLoginToken($email: String!, $token: String!) {
  login(authProvider: {
    emailLoginToken: {
      email: $email
      token: $token
    }
  }) {
    ...${LOGIN_FRAGMENT.name}
  }
}
`;

export const VERIFY_EMAIL_LOGIN_CODE_MUTATION = gql`
${LOGIN_FRAGMENT.fragment}
mutation ($email: String!, $token: String!) {
  verifyEmailLoginCode(email: $email, token: $token) {
    ...${LOGIN_FRAGMENT.name}
  }
}
`;

export const RESEND_VERIFY_EMAIL_NEW_ACCOUNT = gql`
mutation {
  resendVerifyEmailNewAccount
}
`;


export const REQUEST_MAGIC_LINK_TOKEN = gql`
  mutation($email: String!) {
    requestMagicLinkToken(email: $email)
  }
`;

export const REQUEST_VERIFICATION_CODE = gql`
  mutation($phoneNumber: String!, $noSignup: Boolean) {
    requestVerificationCode(phoneNumber: $phoneNumber, noSignup: $noSignup)
  }
`;

export const MARK_GETTING_STARTED_AS_COMPLETED_MUTATION = gql`
  mutation {
    markGettingStartedAsCompleted {
      id
      isGettingStartedCompleted
    }
  }
`;

export const SET_WORK_RELATIONSHIP_FROM_INVITATION_MUTATION = gql`
  mutation($invitationUuid: ID!, $isWorkingTogether: Boolean!) {
    setWorkRelationshipViaInvitation(
      invitationUuid: $invitationUuid
      isWorkingTogether: $isWorkingTogether
    )
  }
`;

export const REMOVE_SKILL_MUTATION = gql`
  mutation($skillId: ID!) {
    removeSkill(skillId: $skillId)
  }
`;

export const CREATE_SKILL_MUTATION = gql`
${SKILL_COMMON_FIELDS_FRAGMENT.fragment}

mutation (
  $name: String!
  $description: String
  $colorPalette: SkillColorPalette
) {
  createCustomSkill(
    name: $name,
    description: $description,
    colorPalette: $colorPalette
  ) {
    ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
  }
}
`;

export const EDIT_CUSTOM_SKILL_MUTATION = gql`
${SKILL_COMMON_FIELDS_FRAGMENT.fragment}

mutation (
  $id: ID!
  $name: String!
  $description: String
  $colorPalette: SkillColorPalette
) {
  editCustomSkill(
    id: $id,
    name: $name,
    description: $description,
    colorPalette: $colorPalette
  ) {
    ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
  }
}
`;

export const PICK_RECOMMENDED_SKILL_MUTATION = gql`
${SKILL_ALL_FIELDS_FRAGMENT.fragment}

mutation (
  $skillId: ID!
) {
  pickRecommendedSkill(
    skillId: $skillId
  ) {
    ...${SKILL_ALL_FIELDS_FRAGMENT.name}
  }
}
`;

export const SET_USER_BASICS_MUTATION = gql`
  mutation(
    $fullName: String
  ) {
    setUserBasics(
      fullName: $fullName
    ) {
      id
      fullName
      firstName
      lastName
    }
  }
`;

export const SET_USER_SETTINGS_MUTATION = gql`
  mutation(
    $headline: String
    $fullName: String
    $bio: String
  ) {
    setUserSettings(
      headline: $headline
      fullName: $fullName
      bio: $bio
    ) {
      id
      fullName
      firstName
      lastName
      headline
      bio
    }
  }
`;

export const SET_NOTIFICATION_SETTING_MUTATION = gql`
${USER_NOTIFICATION_SETTINGS_FRAGMENT.fragment}

mutation setNotificationSetting (
  $label: String!
  $category: String!
  $value: Boolean!
) {
  setNotificationSetting(
    label: $label
    category: $category
    value: $value
  ) {
    id
    notificationSettings {
      ...${USER_NOTIFICATION_SETTINGS_FRAGMENT.name}
    }
  }
}
`;

export const USER_ID_AUTH_TOKEN_LOGIN_MUTATION = gql`
${LOGIN_FRAGMENT.fragment}

mutation (
  $token: String!
) {
  login(authProvider: {
    userIdToken: {
      token: $token
    }
  }) {
    ...${LOGIN_FRAGMENT.name}
  }
}
`;

export const REQUEST_FEEDBACK_MUTATION = gql`
  mutation (
    $customNote: String,
    $feedbackTemplateId: ID,
    $pickedPeers: [PickedPeer]!
    $source: String!
    $isRecommendation: Boolean
    $workspaceId: ID
  ) {
    requestFeedback(
      customNote: $customNote
      feedbackTemplateId: $feedbackTemplateId
      pickedPeers: $pickedPeers
      source: $source
      isRecommendation: $isRecommendation
      workspaceId: $workspaceId
    ) {
      id
    }
  }
`;

export const PEER_ACTIONS_STATUS_QUERY = gql`
  query($userId: ID, $email: String) {
    getPeerActionsStatus(userId: $userId, email: $email) {
      requestFeedback {
        pendingInvitation {
          isRecent
        }
        canBeRequested
      }
      quickNote {
        canBeGiven
      }
      unsolicitedFeedback {
        canBeGiven
      }
      kudos {
        canBeGiven
      }
    }
  }
`;

export const SET_EMAIL_MUTATION = gql`
  mutation($email: String!) {
    setEmail(email: $email) {
      id
      email
    }
  }
`;

export const SET_FULL_NAME_MUTATION = gql`
  mutation($fullName: String!) {
    setFullName(fullName: $fullName) {
      id
      firstName
      lastName
      fullName
    }
  }
`;

export const UPLOAD_USER_PHOTO_MUTATION = gql`
  mutation($photoUrl: String!) {
    uploadPhoto(photoUrl: $photoUrl) {
      id
      photoUrl
    }
  }
`;

export const REMOVE_USER_PHOTO_MUTATION = gql`
  mutation {
    removePhoto {
      id
      photoUrl
    }
  }
`;

export const UPDATE_PHOTO_MUTATION = gql`
  mutation($provider: String!) {
    updatePhoto(provider: $provider) {
      id
      photoUrl
    }
  }
`;
export const MARK_NOTIFICATIONS_AS_VIEWED_MUTATION = gql`
  mutation($ids: [ID!]!) {
    markNotificationAsViewed(ids: $ids) {
      id
      isViewed
    }
  }
`;

export const MARK_ALL_NOTIFICATIONS_AS_VIEWED_MUTATION = gql`
  mutation {
    markAllNotificationsAsViewed {
      id
      isViewed
    }
  }
`;

export const SET_WORK_RELATIONSHIP_WITH_PEER_MUTATION = gql`
${CONNECTION_ALL_FIELDS_FRAGMENT.fragment}

mutation (
  $email: String!
  $isWorkingTogether: Boolean!
) {
  setWorkRelationshipWithPeer(
    email: $email
    isWorkingTogether: $isWorkingTogether
  ) {
    id
    connections {
      ...${CONNECTION_ALL_FIELDS_FRAGMENT.name}
    }
  }
}
`;

export const SYNC_PEER_RECOMMENDATIONS_MUTATION = gql`
mutation {
  syncPeerRecommendations(
    provider: "google"
    resourceType: "calendar.readonly"
  ) {
    id
  }
}
`;

export const ADD_ADVISOR_BY_EMAIL_MUTATION = gql`
  mutation($email: String!) {
    addAdvisorByEmail(email: $email) {
      personId
      email
    }
  }
`;

export const REMOVE_ADVISOR_BY_PERSON_ID_MUTATION = gql`
  mutation($id: ID!) {
    removeAdvisorByPersonId(id: $id)
  }
`;

export const CREATE_OR_OVERWRITE_RECURRING_FEEDBACK_MUTATION = gql`
  ${RECURRING_FEEDBACK_FRAGMENT.fragment}

  mutation(
    $personId: ID!
    $customNote: String
    $recurrence: RecurrenceSettingsInput!
    $sendImmediately: Boolean!
    $skillIds: [ID!]!
    $workspaceId: ID
  ) {
    createOrOverwriteRecurringFeedback(
      personId: $personId
      customNote: $customNote
      recurrence: $recurrence
      sendImmediately: $sendImmediately
      skillIds: $skillIds
      workspaceId: $workspaceId
    ) {
      id
      person {
        id
        userId
        email
        fullName
        firstName
        photoUrl
      }
      recurringFeedback {
        ...${RECURRING_FEEDBACK_FRAGMENT.name}
      }
    }
  }
`;

export const INACTIVATE_RECURRING_FEEDBACK_MUTATION = gql`
  ${RECURRING_FEEDBACK_FRAGMENT.fragment}

  mutation($id: ID! $workspaceId: ID!) {
    inactivateRecurringFeedback(id: $id workspaceId: $workspaceId) {
      id
      person {
        id
        userId
        email
        fullName
        firstName
        photoUrl
      }
      recurringFeedback {
        ...${RECURRING_FEEDBACK_FRAGMENT.name}
      }
    }
  }
`;

export const REVOKE_LONG_FORM_FEEDBACK_INVITATION_MUTATION = gql`
  ${FEEDBACK_REQUEST_REMINDERS_FIELDS_FRAGMENT.fragment}
  ${FEEDBACK_REQUEST_STATUS_TYPES_FRAGMENT.fragment}

  mutation ($invitationId: ID!) {
    revokeLongFormFeedbackInvitation(invitationId: $invitationId) {
      id
      eventTime
      invitedPeer {
        email
        fullName
        userId
        photoUrl
      }
      feedbackRequestStatus {
        ...${FEEDBACK_REQUEST_STATUS_TYPES_FRAGMENT.name}
        changedAt
      }
      reminders {
        ...${FEEDBACK_REQUEST_REMINDERS_FIELDS_FRAGMENT.name}
      }
    }
  }
`;

export const HIDE_PEER_CONNECTION_MUTATION = gql`
${CONNECTION_ALL_FIELDS_FRAGMENT.fragment}

mutation (
  $id: ID!
) {
  hidePeerConnection(id: $id) {
    id
    connections {
      ...${CONNECTION_ALL_FIELDS_FRAGMENT.name}
    }
  }
}
`;

export const CONNECTIONS_QUERY = gql`

${CONNECTION_ALL_FIELDS_FRAGMENT.fragment}

query {
  currentUser {
    id
  }
}
`;

export const FEEDITEM_THANKS_MUTATION = gql`
  mutation($feedItemId: ID!, $isThanked: Boolean!) {
    toggleFeedItemThanks(feedItemId: $feedItemId, isThanked: $isThanked) {
      id
      thanks {
        canThank
        canSeeThank
        thanks {
          id
          author {
            id
            fullName
            firstName
            photoUrl
          }
          createdAt
          canDelete
        }
        numberThanks
      }
    }
  }
`;

export const GETTING_STARTED_CHECKLIST_QUERY = gql`
  query {
    currentUser {
      id
      firstName
      bio
      photoUrl
    }
  }
`;

export const FABULOUS_WORK_QUERY = gql`
  query {
    currentUser {
      id
      firstName
    }
  }
`;

export const IGNORE_INVITATION_MUTATION = gql`
  mutation($invitationId: ID!) {
    markInvitationAsIgnored(invitationId: $invitationId) {
      id
      isIgnored
    }
  }
`;

export const DISCONNECT_CONNECTED_ACCOUNT_MUTATION = gql`
mutation ($connectedAccountId: ID!) {
  disconnectConnectedAccount(connectedAccountId: $connectedAccountId) {
    id
  }
}
`;

export const MINI_PROFILE_QUERY = gql`
  query miniProfileQuery($workspaceId: ID!, $tenantId: ID!) {
    outboundInvitations(workspaceId: $workspaceId) {
      id
      feedbackRequestStatus {
        type
        subType
      }
    }
    feedbacksGiven(workspaceId: $workspaceId) {
      id
    }
    feedbackReceivedFeedItems(isNonSelfOnly: true, tenantId: $tenantId) {
      id
    }
  }
`;

export const GIVE_QUICK_NOTE_FEEDBACK_MUTATION = gql`
mutation (
  $receiverUserId: ID!
  $note: String!
  $skillId: ID
  $workspaceId: ID
) {
  giveQuickNoteFeedback(
    receiverUserId: $receiverUserId
    note: $note
    skillId: $skillId
    workspaceId: $workspaceId
  ) {
    id
  }
}
`;

export const QUICK_NOTE_INNER_QUERY = gql`
${SKILL_COMMON_FIELDS_FRAGMENT.fragment}

query (
  $id: ID!
) {
  user(id: $id) {
    skills {
      ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
    }
  }
  recommendedSkills {
    ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
  }
}
`;

export const CONNECT_CONTACTS_QUERY = gql`
query {
  currentUser {
    id
    email
  }
}
`;

export const MARK_FEEDBACK_NOTIFICATIONS_AS_VIEWED_MUTATION = gql`
  mutation($feedbackId: ID!) {
    markFeedbackNotificationsAsViewed(feedbackId: $feedbackId) {
      id
      isViewed
    }
  }
`;

export const MARK_FEEDBACK_COMMENTS_AS_VIEWED_MUTATION = gql`
  mutation($feedbackId: ID!) {
    markFeedbackCommentsAsViewed(feedbackId: $feedbackId) {
      id
    }
  }
`;

export const MARK_FEEDBACKS_AS_VIEWED_MUTATION = gql`
  mutation($ids: [ID!]!) {
    markFeedbacksAsViewed(ids: $ids)
  }
`;

export const FEED_QUERY = gql`
${FEEDITEM_COMMON_FIELDS_FRAGMENT.fragment}

query($offset: Int) {
  currentUser {
    id
    photoUrl
  }
  homeFeed(offset: $offset) {
    feedItems {
      ...${FEEDITEM_COMMON_FIELDS_FRAGMENT.name}
    }
    count
  }
}
`;

export const NEW_FEEDBACKS_QUERY = gql`
${SKILL_COMMON_FIELDS_FRAGMENT.fragment}
query($tenantId: ID!) {
  unreadFeedback(tenantId: $tenantId) {
    id
    eventTime
    canDelete
    createdAt
    workspace {
      id
    }
    payload {
      __typename
      ... on KudosPayload {
          feedbackId
          kudos {
            type
            note
          }
          receivers {
            id 
            personId
            fullName
            firstName
            email
            photoUrl
          }
          giver {
            id
            personId
            fullName
            firstName
            email
            photoUrl
          }
      }
      ... on QuickNotePayload {
        feedbackId
        quickNote {
          note
          skill {
            ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
          }
        }
        receiver {
            id 
            personId
            fullName
            firstName
            email
            photoUrl
          }
        giver {
          id
          personId
          fullName
          firstName
          email
          photoUrl
        }
      }
      ... on LongformPayload {
        feedbackId
        feedback {
          id
          feedbackType
          completedAt
          overallRating
          skillRatings {
            skill {
              ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
            }
            rating
            note
            actionItems {
              id
              name
              description
            }
          }
          abilityRatings {
            ability {
              id
              name
              description
            }
            rating
          }
          qualityEndorsements {
            name
          }
          latestPerformance {
            rating
            note
          }
        }
        receiver {
          id 
          personId
          fullName
          firstName
          email
          photoUrl
        }
        giver {
          id
          personId
          fullName
          firstName
          email
          photoUrl
        }
      }
    }
  }
}
`;

export const FEEDBACK_FLOW_QUERY = gql`
${FEEDBACK_FLOW_FEEDITEM_COMMON_FIELDS_FRAGMENT.fragment}

query($feedItemIds: [ID!], $tenantId: ID!) {
  newFeedbacksReceived: feedbackReceivedFeedItems(isUnviewedOnly: false, isNonSelfOnly: true, feedItemIds: $feedItemIds, tenantId: $tenantId) {
    ...${FEEDBACK_FLOW_FEEDITEM_COMMON_FIELDS_FRAGMENT.name}
  }
  totalFeedbacksReceived: feedbackReceivedFeedItems(isUnviewedOnly: false, isNonSelfOnly: true, tenantId: $tenantId) {
    id
    eventTime
  }
}
`;

export const ONBOARDING_FLOW_QUERY = gql`
  query {
    currentUser {
      id
      fullName
      headline
      workspaces {
        id
        iconUrl
        isOwner
        isDeleted
        isPending
        name
      }
    }
  }
`;

export const START_ONBOARDING_MUTATION = gql`
  mutation {
    startOnboarding
  }
`;

export const SET_HEADLINE_MUTATION = gql`
  mutation($headline: String!) {
    setHeadline(headline: $headline) {
      id
      headline
    }
  }
`;

export const SET_CURRENT_JOB_CATEGORY_MUTATION = gql`
  mutation($jobCategoryId: ID!) {
    setCurrentJobCategory(jobCategoryId: $jobCategoryId) {
      currentJobCategory {
        title
      }
    }
  }
`;

export const SET_YEARS_OF_EXPERIENCE_MUTATION = gql`
  mutation($yearsOfExperience: String!) {
    setYearsOfExperience(yearsOfExperience: $yearsOfExperience) {
      id
      yearsOfExperience
    }
  }
`;

export const ALL_JOB_CATEGORIES_QUERY = gql`
  query {
    jobCategories {
      id
      title
    }
  }
`;

export const ALL_QUALITIES_QUERY = gql`
  query {
    qualities {
      id
      name
    }
  }
`;

export const CURRENT_USER_SKILLS_QUERY = gql`
  query {
    skills {
      id
      name
      description
    }
    recommendedSkills {
      id
      name
      description
    }
  }
`;

export const GET_EMAIL_PROVIDER_QUERY = gql`
  query {
    emailProvider
    currentUser {
      id
      email
    }
  }
`;

export const SKILL_DETAILS_QUERY = gql`
${SKILL_ALL_FIELDS_FRAGMENT.fragment}
query ($workspaceIds: [ID]) {
  pickedSkills (workspaceIds: $workspaceIds) {
    skill {
        ...${SKILL_ALL_FIELDS_FRAGMENT.name}
      }
    average
    numFeedbacks
  }
}
`;

export const SKILL_DETAILS_2_PAGE_QUERY = gql`
  ${SKILL_COMMON_FIELDS_FRAGMENT.fragment}
  ${COMPLETED_FEEDBACK_FEEDITEM_COMMON_FIELDS.fragment}

  query ($skillSlug: String!, $workspaceIds: [ID], $limit: Int) {
    skillFeedbackFeedItems(skillSlug: $skillSlug, workspaceIds: $workspaceIds, limit: $limit) {
      startDate
      skill {
        ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
      }
      feedbackFeedItems {
        ...${COMPLETED_FEEDBACK_FEEDITEM_COMMON_FIELDS.name}
      }
    }
    pickedSkills (workspaceIds: $workspaceIds) {
      skill {
        ...${SKILL_COMMON_FIELDS_FRAGMENT.name}
      }
      average
      numFeedbacks
    }
  }
`;

export const BEGIN_FEEDBACK_MUTATION = gql`
  mutation($invitationUuid: ID!) {
    beginFeedback(invitationUuid: $invitationUuid)
  }
`;

export const TRANSFER_FEEDBACK_DATA_FROM_SESSION_TO_USER = gql`
  mutation($invitationUuid: ID!) {
    transferFeedbackDataFromSessionToUser(invitationUuid: $invitationUuid)
  }
`;

export const END_GIVE_FEEDBACK_SESSION_BY_INVITATION_MUTATION = gql`
  mutation($invitationUuid: ID!) {
    endGiveFeedbackSession(
      finder: { id: $invitationUuid, finderType: INVITATION_UUID }
    )
  }
`;

export const SET_QUALITIES_MUTATION = gql`
  mutation($qualityIds: [ID!]!, $feedbackId: ID!) {
    endorseQualities(
      finder: { id: $feedbackId, finderType: FEEDBACK_ID }
      qualityIds: $qualityIds
    )
  }
`;

export const RECURRING_SELECT_SKILLS_IDS_MUTATION = gql`
  mutation($feedbackId: ID!, $skillIds: [ID!]!) {
    selectSkillsForFeedback(
      finder: { id: $feedbackId, finderType: FEEDBACK_ID }
      skillIds: $skillIds
    )
  }
`;

export const RATE_FEEDBACK_MUTATION = gql`
  mutation($feedbackId: ID!, $rating: Int!) {
    rateFeedback(
      finder: { id: $feedbackId, finderType: FEEDBACK_ID }
      rating: $rating
    )
  }
`;

export const RATE_SKILL_MUTATION = gql`
  mutation($feedbackId: ID!, $skillId: ID!, $rating: Int!, $note: String) {
    rateSkill(
      finder: { id: $feedbackId, finderType: FEEDBACK_ID }
      skillId: $skillId
      rating: $rating
      note: $note
    )
  }
`;

export const RATE_SKILL_BATCH_MUTATION = gql`
  mutation($feedbackId: ID!, $skills: [SkillRatingInput!]!) {
    rateSkillBatch(
      finder: { id: $feedbackId, finderType: FEEDBACK_ID }
      skills: $skills
    )
  }
`;

export const PICK_ACTIONS_ITEMS_FOR_SKILL_MUTATION = gql`
  mutation($feedbackId: ID!, $skillId: ID!, $abilityIds: [ID!]!) {
    pickActionItemsForSkill(
      finder: { id: $feedbackId, finderType: FEEDBACK_ID }
      skillId: $skillId
      abilityIds: $abilityIds
    )
  }
`;

export const FEEDBACK_QUALITIES_QUERY = gql`
  query($invitationUuid: ID!) {
    giveFeedbackSession(
      finder: { id: $invitationUuid, finderType: INVITATION_UUID }
    ) {
      id
      receiver {
        qualitiesToRate {
          id
          name
        }
        firstName
        fullName
        photoUrl
      }
    }
  }
`;

export const INVITATION_FROM_UUID_QUERY = gql`
  query($invitationUuid: ID!) {
    invitation(invitationUuid: $invitationUuid) {
      id
      owner {
        id
        firstName
        fullName
        photoUrl
        headline
        isDeleted
      }
      giverFullName
      giver {
        isSelf
      }
    }
  }
`;

export const THANK_YOU_QUERY = gql`
 ${COMPLETED_FEEDBACK_FEEDITEM_COMMON_FIELDS.fragment}

  query($invitationUuid: ID!) {
    invitation(invitationUuid: $invitationUuid) {
      owner {
        id
        email
        firstName
        fullName
        photoUrl
      }
      lastCompletedFeedbackFeedItem {
        ...${COMPLETED_FEEDBACK_FEEDITEM_COMMON_FIELDS.name}
      }
      recurringFeedback {
        id
        recurrence {
          frequency
          day
        }
      }
    }
    currentUser {
      id
      firstName
      isOnboarded
    }
    pendingInvitations {
      id
      isCompleted
      isIgnored
      eventTime
      path
      owner {
        id
        fullName
        photoUrl
      }
    }
  }
`;

export const SKILLS_QUERY = gql`
${SKILL_ALL_FIELDS_FRAGMENT.fragment}

query {
  recommendedSkills {
    id
    name
    abilities {
      id
      name
    }
  }
  currentUser {
    id
    pickedSkillDetails {
      isRated
      skill {
        ...${SKILL_ALL_FIELDS_FRAGMENT.name}
      }
    }
  }
}
`;

export const REGISTER_CONNECTED_ACCOUNT_MUTATION = gql`
mutation (
  $provider: String!
  $accessTokenExpiresIn: String
  $accessToken: String!
  $accessTokenSecret: String
  $refreshToken: String
  $resourceType: String
  $accountName: String
) {
  registerConnectedAccount(
    provider: $provider
    accessTokenExpiresIn: $accessTokenExpiresIn
    accessToken: $accessToken
    accessTokenSecret: $accessTokenSecret
    refreshToken: $refreshToken
    resourceType: $resourceType
    accountName: $accountName
  ) {
    id
  }
}
`;

export const RESEND_VERIFY_EMAIL_MUTATION = gql`
  mutation resendVerifyEmailLink($email: String!) {
    resendVerifyEmailLink(email: $email)
  }
`;

export const SEND_ADD_EMAIL_PREFLIGHT_CHECK_MUTATION = gql`
  mutation {
    sendAddEmailPreFlightCheck
  }
`;

export const ADD_EMAIL_MUTATION = gql`
  ${ACCOUNT_SETTINGS_FRAGMENT.fragment}
  mutation addEmail($email: String!, $token: String) {
    addEmail(email: $email, token: $token) {
      ...${ACCOUNT_SETTINGS_FRAGMENT.name}
    }
  }
`;

export const DELETE_EMAIL_MUTATION = gql`
  ${ACCOUNT_SETTINGS_FRAGMENT.fragment}
  mutation deleteEmail($email: String!) {
    deleteEmail(email: $email) {
      ...${ACCOUNT_SETTINGS_FRAGMENT.name}
    }
  }
`;

export const MAKE_PRIMARY_EMAIL = gql`
  ${ACCOUNT_SETTINGS_FRAGMENT.fragment}
  mutation makePrimaryEmail($email: String!) {
    makePrimaryEmail(email: $email) {
      ...${ACCOUNT_SETTINGS_FRAGMENT.name}
    }
  }
`;

export const SEND_DELETION_CODE_MUTATION = gql`
  mutation sendDeletionCode($reason: String!, $note: String) {
    sendDeletionCode(reason: $reason, note: $note)
  }
`;

export const RESEND_DELETION_CODE_MUTATION = gql`
  mutation {
    resendDeletionCode
  }
`;

export const DELETE_ACCOUNT_MUTATION = gql`
  mutation deleteAccount($code: String!) {
    deleteAccount(code: $code)
  }
`;

export const USER_SETTINGS_ACCOUNT_QUERY = gql`
  query {
    currentUser {
      id
      email
      emails {
        id
        value
        primary
        verified
        updatedAt
      }
      phoneNumber
      isEmailVerified
      workspaces {
        id
        name
        iconUrl
        isAdmin
        isOwner
        members {
          id
          status
        }
      }
    }
  }
`;

export const GETTING_STARTED_QUERY = gql`
  query gettingStarted {
    currentUser {
      id
      isEmailVerified
      email
      bio
      photoUrl
      headline
      isGettingStartedCompleted
    }
  }
`;

export const FIND_OR_CREATE_FEEDBACK_TEMPLATE_MUTATION = gql`
  mutation findOrCreateFeedbackTemplate($skillIds: [ID!]!) {
    findOrCreateFeedbackTemplate(skillIds: $skillIds) {
      id
    }
  }
`;

export const FIND_OR_CREATE_URL_INVITATION_MUTATION = gql`
  mutation findOrCreateOnDemandURLInvitation(
    $feedbackTemplateId: ID
    $customNote: String
    $source: String
  ) {
    findOrCreateOnDemandURLInvitation(
      feedbackTemplateId: $feedbackTemplateId
      customNote: $customNote
      source: $source
    ) {
      id
      url
    }
  }
`;

export const RATE_ABILITY_MUTATION = gql`
  mutation($feedbackId: ID!, $abilityId: ID!, $rating: Int!) {
    rateAbility(
      finder: { id: $feedbackId, finderType: FEEDBACK_ID }
      abilityId: $abilityId
      rating: $rating
    )
  }
`;

export const END_GIVE_FEEDBACK_SESSION_MUTATION = gql`
  mutation($feedbackId: ID!) {
    endGiveFeedbackSession(finder: { id: $feedbackId, finderType: FEEDBACK_ID })
  }
`;

export const UPDATE_NOTE_FOR_SKILL_RATING_MUTATION = gql`
  mutation updateNoteForSkillRating(
    $finder: GiveFeedbackSessionFinder!
    $skillId: ID!
    $note: String!
  ) {
    updateNoteForSkillRating(finder: $finder, skillId: $skillId, note: $note)
  }
`;

export const GIVE_KUDOS_MUTATION = gql`
  mutation giveKudos(
    $receiverUserIds: [ID!]!
    $type: String!
    $note: String!
    $workspaceId: ID
    $isPublic: Boolean
  ) {
    giveKudos(
      receiverUserIds: $receiverUserIds,
      type: $type,
      note: $note,
      workspaceId: $workspaceId,
      isPublic: $isPublic
    ) {
      id
      kudosLeft
    }
  }
`;

export const ADD_FEEDITEM_COMMENT_MUTATION = gql`
  mutation addFeedItemComment(
    $feedItemId: ID!
    $comment: String!
  ) {
    addFeedItemComment(
      feedItemId: $feedItemId,
      comment: $comment
    ) {
      id
      createdAt
      eventTime
      comments {
        id
        createdAt
        message
        canDelete
        author {
          id
          fullName
          firstName
          photoUrl
        }
      }
    }
  }
`;

export const DELETE_FEEDITEM_COMMENT_MUTATION = gql`
  mutation deleteFeedItemComment(
    $feedItemId: ID!
    $commentId: ID!
  ) {
    deleteFeedItemComment(
      feedItemId: $feedItemId,
      commentId: $commentId
    ) {
      id
    }
  }
`;

export const DELETE_FEEDITEM_MUTATION = gql`
  mutation deleteFeedItem(
    $feedItemId: ID!
    $isHomeFeed: Boolean
    $isAdmin: Boolean
  ) {
    deleteFeedItem(
      feedItemId: $feedItemId,
      isHomeFeed: $isHomeFeed,
      isAdmin: $isAdmin,
    )
  }
`;

export const DELETE_FEEDITEM_RECIPIENT_MUTATION = gql`
  mutation deleteFeedItemRecipient(
    $feedItemId: ID!
    $receiverId: ID!
  ) {
    deleteFeedItemRecipient(
      feedItemId: $feedItemId,
      receiverId: $receiverId
    )
  }
`;
