import gql from 'graphql-tag';

const updateCelebrationSettings = gql`
  mutation setCelebrationSettings($workspaceId: ID!, $settings: CelebrationsSettingsInput!) {
    setCelebrationSettings(workspaceId: $workspaceId, settings: $settings) {
      id
      celebrationSettings {
        isBirthdaysActive
        isAnniversariesActive
        birthdayCoinsAwarded
        anniversaryCoinsAwarded
        autoCollectBirthdays
        autoCollectAnniversaries
        birthdayMessage
        anniversaryMessage
        isBirthdaysNotifyAll
        isAnniversariesNotifyAll
        usersCanModifyCelebrationDates
        autoCollectBirthdaysStartDate
        autoCollectAnniversariesStartDate
        anniversaryCustomAwards { years, coins }
        sendOnWeekendDays
      }
    }
  }
`;

export default updateCelebrationSettings;
